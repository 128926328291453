import React from "react"
import { window } from "browser-monads"
import { Link , navigate} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  API_CALL_STATUSES,
  authAjax,
  deleteCookie,
  getCookie,
  getQueryParam,
  getRedirectPathFromUrl,
} from "../utils"
import Progress from "../components/progress"
import CenteredContainer from "../components/CenteredContainer"
import { FancyCardHeader } from "../components/FancyInput"

const { authURL } = window;

const LOGOUT_STATE = {
  /**
   * Normal logout flow
   */
  DEFAULT: 'default',
  /**
   * When user is not logged in but /logout is called
   */
  NOT_LOGGED_IN: 'not_logged_in',
  /**
   * When user is logging out from rapyuta.io OIDC flow
   */
  OIDC: 'oidc',
};

const oidcLogoutFormID = 'oidcLogoutForm';

class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoutStatus: API_CALL_STATUSES.IDLE,
      logoutState: LOGOUT_STATE.DEFAULT,
      logoutChallenge: getQueryParam("logout_challenge"),
      token: getCookie("token"),
    }
  }

  componentDidMount() {
    const { logoutChallenge, token } = this.state;
    if (logoutChallenge) {
      /**
       * If logoutChallenge is present, user is logging out from OIDC Hydra session
       */
      this.setState({ logoutState: LOGOUT_STATE.OIDC });
      this.autoSubmitLogout();
    } else {
      /**
       * Otherwise, user is logging out from rapyuta.io session
       */
      if (token) {
        this.callLogout()
      } else {
        navigate(`/${getRedirectPathFromUrl()}`)
        this.setState({ logoutState: LOGOUT_STATE.NOT_LOGGED_IN });
      }
    }
  }

  callLogout = () => {
    this.setState({
      logoutStatus: API_CALL_STATUSES.PROGRESS,
    })
    authAjax({
      path: "user/logout",
      type: "POST",
      success: () => {
        deleteCookie("token")
        this.setState({
          logoutStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: err => {
        const errStatus = err.status
        if (errStatus === 401) {
          deleteCookie("token")
          this.setState({
            logoutStatus: API_CALL_STATUSES.SUCCESS,
          })
        } else {
          this.setState({
            logoutStatus: API_CALL_STATUSES.ERROR,
          })
        }
      },
    })
  }

  isNotLoggedIn() {
    return this.state.logoutState === LOGOUT_STATE.NOT_LOGGED_IN;
  }

  isOIDCLogout() {
    return this.state.logoutState === LOGOUT_STATE.OIDC;
  }

  autoSubmitLogout = () => {
    const logoutForm = document.getElementById(oidcLogoutFormID);
    HTMLFormElement.prototype.submit.call(logoutForm);
  }

  render() {
    const { logoutStatus } = this.state
    return (
      <Layout bgimage dark stickyFooter>
        <SEO title="Logout" />
        {logoutStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <CenteredContainer>
              {logoutStatus === API_CALL_STATUSES.PROGRESS && (
                <div id="pending">
                  <h3>LOGGING OUT USER</h3>
                  <p className="subText">Please wait...</p>
                </div>
              )}
              {logoutStatus === API_CALL_STATUSES.SUCCESS && (
                <div id="success">
                  <h3>SUCCESSFULLY LOGGED OUT</h3>
                  <p className="subText">
                    To login again, please{" "}
                    <Link to={`/login/${getRedirectPathFromUrl()}`}>
                    <span className="link text-red-600">click here</span>
                    </Link>
                  </p>
                </div>
              )}
              {logoutStatus === API_CALL_STATUSES.ERROR && (
                <div id="errorLogout">
                  <h3>ERROR LOGGING OUT</h3>
                  <p className="subText">
                    To try again, please{" "}
                    <button onClick={this.callLogout} className="textBtn">
                      click here
                    </button>
                    <br />
                    To login, please{" "}
                    <Link to={`/login/${getRedirectPathFromUrl()}`}>
                      click here
                    </Link>
                  </p>
                </div>
              )}
              {this.isNotLoggedIn() && (
                <div id="notLoggedIn">
                  <h3>INVALID LOGOUT</h3>
                  <p className="subText">
                    You are not logged in.
                    <br />
                    To login, please{" "}
                    <Link to={`/${getRedirectPathFromUrl()}`}>
                      click here
                    </Link>
                  </p>
                </div>
              )}
              {
                this.isOIDCLogout() && (
                  <div>
                    <FancyCardHeader title="OAuth Client Logout" padBottom={false} ></FancyCardHeader>
                    <p className="subText">
                      Logging out from rapyuta.io.
                      <br /><br />
                      Redirecting...
                    </p>
                  </div>
                )
              }
              {/* due to gatsby issue, form has to be wrapped with <p></p> otherwise variables will not be parsed properly*/}
              <p>
                <form
                  id={oidcLogoutFormID}
                  action={`${authURL}user/logout?logout_challenge=${this.state.logoutChallenge}`}
                  method="POST"
                  // This form is only submitted from autoSubmitConsent, hence hidden
                  hidden={true}
                >
                  {/* Backend requires a field called submit with any value other than 'deny'. 'allow' is used for consistency with /consent */}
                  <input type="hidden" name="submit" value="allow" />
                </form>
              </p>
        </CenteredContainer>
      </Layout>
    )
  }
}

export default Logout
